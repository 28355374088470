<template>
  <section
    class="osk-service-work"
  >
    <div class="osk-wrapper">
      <h2 class="osk-service-work__title">
        {{ $t('components.home.serviceWork.title') }}
      </h2>

      <div class="osk-service-work__subtitle">
        {{ $t('components.home.serviceWork.subtitle') }}
      </div>

      <div class="osk-service-work__img-wrapper">
        <div
          v-show="isTrust && isLarge"
          class="osk-service-work__auth"
        >
          {{ $t('components.home.serviceWork.auth') }}
        </div>

        <img
          v-show="isPayment"
          src="~assets/img/home/payment.jpeg"
          alt="payment"
          class="osk-service-work__image"
        >

        <img
          v-show="isSell"
          src="~assets/img/home/sell.jpeg"
          alt="sell"
          class="osk-service-work__image"
        >

        <img
          v-show="isTrust && !isLarge"
          src="~assets/img/home/trust.jpeg"
          alt="trust"
          class="osk-service-work__image"
        >

        <img
          v-show="isTrust && isLarge"
          src="~assets/img/home/trust-desktop.jpeg"
          alt="trust"
          class="osk-service-work__image"
        >

        <div class="osk-service-work__buttons">
          <div
            :class="{
              'osk-service-work__button': true,
              'osk-service-work__button_active': isPayment
            }"
            @click="changeActiveBlock(ETabs.Payment)"
          >
            {{ $t('components.home.serviceWork.buy') }}
          </div>

          <div
            :class="{
              'osk-service-work__button': true,
              'osk-service-work__button_active': isSell
            }"
            @click="changeActiveBlock(ETabs.Sell)"
          >
            {{ $t('components.home.serviceWork.sale') }}
          </div>

          <div
            :class="{
              'osk-service-work__button': true,
              'osk-service-work__button_active': isTrust
            }"
            @click="changeActiveBlock(ETabs.Trust)"
          >
            {{ $t('components.home.serviceWork.trust') }}
          </div>
        </div>
      </div>

      <div class="osk-service-work__block-wrapper">
        <div
          v-show="isPayment"
          class="osk-service-work__block"
        >
          <div class="osk-service-work__block-t osk-mobile-hidden">
            {{ $t('components.home.serviceWork.block1.title') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block1.info1.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block1.info1.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block1.info2.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block1.info2.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block1.info3.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block1.info3.text') }}
          </div>
        </div>

        <div
          v-show="isSell"
          class="osk-service-work__block"
        >
          <div class="osk-service-work__block-t osk-mobile-hidden">
            {{ $t('components.home.serviceWork.block2.title') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block2.info1.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block2.info1.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block2.info2.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block2.info2.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block2.info3.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block2.info3.text') }}
          </div>
        </div>

        <div
          v-show="isTrust"
          class="osk-service-work__block"
        >
          <div class="osk-service-work__block-t osk-mobile-hidden">
            {{ $t('components.home.serviceWork.block3.title') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block3.info1.title') }}
          </div>

          <div class="osk-service-work__block-text osk-service-work__block-text_over">
            {{ $t('components.home.serviceWork.block3.info1.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block3.info2.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block3.info2.text') }}
          </div>

          <div class="osk-service-work__block-title">
            {{ $t('components.home.serviceWork.block3.info3.title') }}
          </div>

          <div class="osk-service-work__block-text">
            {{ $t('components.home.serviceWork.block3.info3.text') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
enum ETabs {
  Payment = 'payment',
  Sell = 'sell',
  Trust= 'trust'
}

const { laptopM, isDesktop, isMounted } = useUiBreakpoints();

const activeBlock = ref(ETabs.Payment);

const isLarge = computed(() => !isMounted.value || laptopM.value || isDesktop.value);

const isPayment = computed(() => isLarge.value || activeBlock.value === ETabs.Payment);
const isSell = computed(() => isLarge.value || activeBlock.value === ETabs.Sell);
const isTrust = computed(() => isLarge.value || activeBlock.value === ETabs.Trust);

function changeActiveBlock(value: ETabs) {
  activeBlock.value = value;
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-service-work {
  padding-bottom: 10px;

  @include media-query(md-and-up) {
    padding-bottom: 0;
  }

  &__title {
    @include font-heading;
    text-transform: uppercase;
    padding: 42px 0 14px;

    @include media-query(md-and-up) {
      text-align: center;
      padding-bottom: 32px;
    }
  }

  &__subtitle {
    font-weight: $font-weight-medium;
    font-size: $font-size-body;
    line-height: $line-height-subheading;
    margin-bottom: $indent-medium;

    @include media-query(md-and-up) {
      text-align: center;
      max-width: 625px;
      margin: 0 auto 32px;
    }
  }

  &__img-wrapper {
    position: relative;
    margin: 0 -16px;

    @include media-query(md-and-up) {
      display: flex;
      justify-content: space-between;
      margin: 0;
    }
  }

  &__auth {
    background: #000000;
    border-radius: 2px;
    padding: 4px 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    position: absolute;
    top: 24px;
    right: 24px;
  }

  &__image {
    display: block;
    width: 100%;

    @include media-query(md-and-up) {
      width: calc(33.333% - 22px);
      border-radius: 4px;
    }
  }

  &__buttons {
    display: flex;
    width: 343px;
    height: 42px;
    padding: 3px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    @include media-query(md-and-up) {
      display: none;
    }
  }

  &__button {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    transition: all linear .3s;
    color: #FFFFFF;

    &_active {
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
      border-radius: 2px;
      color: #000000;
    }
  }

  &__block-wrapper {
    padding-top: 24px;

    @include media-query(md-and-up) {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
    }
  }

  &__block {
    @include media-query(md-and-up) {
      width: calc(33.333% - 22px);
    }
  }

  &__block-t {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__block-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &__block-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
    max-width: 436px;

    @include media-query(lg-and-up) {
      margin-bottom: 0;
      height: 92px;
    }

    &_over {
      @include media-query(lg-and-up) {
        height: 92px;
      }

      @media screen and (min-width: 1040px) {
        height: 72px;
      }

      @media screen and (min-width: 1105px) {
        height: 92px;
      }
    }
  }
}
</style>
