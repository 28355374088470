<template>
  <UikitDialog
    :model-value="modelValue"
    persistent
    position="right"
    content-class="osk-web-to-app-gender-dialog"
    @update:model-value="closeDialog"
  >
    <img
      v-if="pageType === PrimaryPageType.FEMALE"
      class="osk-web-to-app-gender-dialog__img"
      src="~assets/img/home/women-mob.webp"
      alt="women"
    >

    <img
      v-if="pageType === PrimaryPageType.MALE"
      class="osk-web-to-app-gender-dialog__img"
      src="~assets/img/home/men-mob.webp"
      alt="men"
    >

    <img
      v-if="pageType === PrimaryPageType.CHILD"
      class="osk-web-to-app-gender-dialog__img"
      src="~assets/img/home/children-mob.webp"
      alt="children"
    >

    <img
      v-if="pageType === PrimaryPageType.LIFESTYLE"
      class="osk-web-to-app-gender-dialog__img"
      src="~assets/img/home/lifestyle-mob.webp"
      alt="lifestyle"
    >

    <div class="osk-mt-mmd osk-px-md">
      <div
        class="text-heading osk-mt-mmd"
      >
        {{ $t('webToApp.genderDialog.header', { sale }) }}
      </div>

      <div class="osk-mt-lg">
        {{ $t('webToApp.genderDialog.text', { promoCode }) }}
      </div>

      <div class="osk-mt-mlg">
        <UikitButton
          full-width
          href="https://oskelly.onelink.me/kabM/nrfjmavl"
          target="_blank"
        >
          {{ $t('webToApp.genderDialog.downloadBtn') }}
        </UikitButton>

        <UikitButton
          class="osk-mt-sm"
          full-width
          color="secondary"
          @click="stayClick"
        >
          {{ $t('webToApp.genderDialog.stayBtn') }}
        </UikitButton>
      </div>
    </div>
  </UikitDialog>
</template>

<script lang="ts" setup>
import { PrimaryPageType } from '~/restAPI/data-contracts';

defineProps<{
  modelValue: boolean,
  pageType: PrimaryPageType,
  promoCode: string,
  sale: number,
}>();

const emit = defineEmits<{
  (e: 'stay'): void,
  (e: 'update:modelValue', value: boolean): void,
}>();

function stayClick() {
  emit('stay');
  closeDialog();
}

function closeDialog() {
  emit('update:modelValue', false);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-web-to-app-gender-dialog {
  .osk-dialog__close {
    display: none;
  }

  &.osk-dialog__body {
    padding: 0 0 16px;
  }

  &__img {
    height: 266px;
    object-fit: cover;
    object-position: 70% 50%;
  }
}
</style>
