/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Api2ResponseOfInstagramAccountDTO, Api2ResponseOfListOfSocialAccountDTO } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Social<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags social-controller-api-v-2
   * @name GetSocialAccountsUsingGet
   * @summary getSocialAccounts
   * @request GET:/api/v2/social/accounts
   */
  getSocialAccountsUsingGet = (
    query?: {
      /**
       * Id страны
       * @format int64
       */
      countryId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Api2ResponseOfListOfSocialAccountDTO, void>({
      path: `/api/v2/social/accounts`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags social-controller-api-v-2
   * @name GetInstagramSupportAccountUsingGet
   * @summary getInstagramSupportAccount
   * @request GET:/api/v2/social/accounts/instagram/support
   */
  getInstagramSupportAccountUsingGet = (params: RequestParams = {}) =>
    this.request<Api2ResponseOfInstagramAccountDTO, void>({
      path: `/api/v2/social/accounts/instagram/support`,
      method: "GET",
      ...params,
    });
}
