<template>
  <BusinessCelebrityBlock
    :data="data"
    :title="t('components.home.celebrity.title')"
    class="osk-home-celebrity"
  />
</template>

<script lang="ts" setup>
import { useApi } from '~/restAPI';
import { Social } from '~/restAPI/Social';
import { CountryIds } from '~/types/constants';

const { t } = useI18n();
const { createApiInstance } = useApi();
const socialApi = createApiInstance(Social);
const { currentCountry } = useInternational();

const { data } = useLazyAsyncData(() => socialApi.getSocialAccountsUsingGet({
  countryId: currentCountry.value?.id,
}).then((res) => res?.data?.data ?? []));
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-home-celebrity {
  padding-top: 42px;

  @include media-query(lg-and-up) {
    padding-top: 82px;
  }
}
</style>
