<template>
  <section class="osk-home-page-top">
    <div class="osk-wrapper">
      <div class="osk-home-page-top__title osk-mobile-hidden">
        {{ $t('components.home.pageTop.chooseSection') }}
      </div>

      <ul class="osk-home-page-top__list">
        <li class="osk-home-page-top__item">
          <nuxt-link
            :to="`/${genderPages[PrimaryPageType.FEMALE]}`"
            class="osk-home-page-top__link"
            @click.capture="categoryClick($event, PrimaryPageType.FEMALE)"
          >
            <picture>
              <source
                type="image/webp"
                srcset="~assets/img/home/women-mob.webp"
                media="(max-width: 480px)"
              >
              <source
                type="image/webp"
                srcset="~assets/img/home/women.webp"
                media="(max-width: 1024px)"
              >
              <img
                src="~assets/img/home/women.webp"
                alt="women"
                class="osk-home-page-top__img"
                fetchpriority="high"
                loading="eager"
              >
            </picture>
            <span class="osk-home-page-top__link-text">{{ $t('home.pageTop.section.link.women') }}</span>
          </nuxt-link>
        </li>

        <li class="osk-home-page-top__item">
          <nuxt-link
            :to="`/${genderPages[PrimaryPageType.MALE]}`"
            class="osk-home-page-top__link"
            @click.capture="categoryClick($event, PrimaryPageType.MALE)"
          >
            <picture>
              <source
                type="image/webp"
                srcset="~assets/img/home/men-mob.webp"
                media="(max-width: 480px)"
              >
              <source
                type="image/webp"
                srcset="~assets/img/home/men.webp"
                media="(max-width: 1024px)"
              >
              <img
                src="~assets/img/home/men.webp"
                alt="men"
                class="osk-home-page-top__img"
                fetchpriority="high"
                loading="eager"
              >
            </picture>
            <span class="osk-home-page-top__link-text">{{ $t('home.pageTop.section.link.men') }}</span>
          </nuxt-link>
        </li>

        <li class="osk-home-page-top__item">
          <nuxt-link
            :to="`/${genderPages[PrimaryPageType.CHILD]}`"
            class="osk-home-page-top__link"
            @click.capture="categoryClick($event, PrimaryPageType.CHILD)"
          >
            <picture>
              <source
                type="image/webp"
                srcset="~assets/img/home/children-mob.webp"
                media="(max-width: 480px)"
              >
              <source
                type="image/webp"
                srcset="~assets/img/home/children.webp"
                media="(max-width: 1024px)"
              >
              <img
                src="~assets/img/home/children.webp"
                alt="children"
                class="osk-home-page-top__img"
                fetchpriority="high"
                loading="eager"
              >
            </picture>
            <span class="osk-home-page-top__link-text">{{ $t('home.pageTop.section.link.kids') }}</span>
          </nuxt-link>
        </li>

        <li class="osk-home-page-top__item">
          <nuxt-link
            :to="`/${genderPages[PrimaryPageType.LIFESTYLE]}`"
            class="osk-home-page-top__link"
            @click.capture="categoryClick($event, PrimaryPageType.LIFESTYLE)"
          >
            <picture>
              <source
                type="image/webp"
                srcset="~assets/img/home/lifestyle-mob.webp"
                media="(max-width: 480px)"
              >
              <source
                type="image/webp"
                srcset="~assets/img/home/lifestyle.webp"
                media="(max-width: 1024px)"
              >
              <img
                src="~assets/img/home/lifestyle.webp"
                alt="lifestyle"
                class="osk-home-page-top__img"
                fetchpriority="high"
                loading="eager"
              >
            </picture>
            <span class="osk-home-page-top__link-text">{{ $t('home.pageTop.section.link.lifestyle') }}</span>
          </nuxt-link>
        </li>
      </ul>
    </div>

    <WebToAppGenderDialog
      v-if="$device.isMobile"
      v-model="webToAppGenderDialogIsOpen"
      :page-type="selectedPageType"
      :promo-code="promoCode"
      :sale="sale"
      @stay="onStayWebToAppGenderDialog"
    />
  </section>
</template>

<script lang="ts" setup>
import { PrimaryPageType } from '~/restAPI/data-contracts';
import useWebToApp from '~/composables/useWebToApp';
import { LS_KEY_WEB_TO_APP_GENDER_DIALOG } from 'assets/constants';

const showWebToAppGenderDialog = useLocalStorage(LS_KEY_WEB_TO_APP_GENDER_DIALOG, true);
const { sale, promoCode } = useWebToApp();
const { isDesktop } = useDevice();
const { genderPages } = useGender();
const { changeActiveMenuCategory } = useActiveMenuCategory();
const webToAppGenderDialogIsOpen = ref(false);
const selectedPageType = ref <PrimaryPageType>(PrimaryPageType.FEMALE);

function categoryClick(e: Event, pageType: PrimaryPageType) {
  if (isDesktop) {
    return;
  }

  if (!showWebToAppGenderDialog.value) {
    changeActiveMenuCategory(genderPages[pageType]);
  } else {
    e.preventDefault();
    selectedPageType.value = pageType;
    webToAppGenderDialogIsOpen.value = true;
  }
}

function onStayWebToAppGenderDialog() {
  showWebToAppGenderDialog.value = false;
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-home-page-top {
  &__title {
    @include font-heading;
    padding: $indent-mmedium 0 $indent-large;
    text-align: center;
    text-transform: uppercase;
  }

  &__list {
    margin-top: 12px;

    @include media-query(md-and-up) {
      display: flex;
      margin: -10px;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    @include media-query(md-and-up) {
      width: 282px + $indent-compact*2;
      padding: $indent-compact;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    object-fit: cover;
    height: 130px;
    width: 100%;

    @include media-query(sm-and-up) {
      height: 180px;
    }

    @include media-query(md-and-up) {
      height: auto;
    }
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
  }

  &__link-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    position: absolute;
    bottom: 12px;
    left: 12px;

    @include media-query(md-and-up) {
      @include font-style($font-size-heading, $line-height-heading, $font-weight-bold);
      text-transform: uppercase;
      bottom: 20px;
      left: 20px;
    }
  }
}
</style>
